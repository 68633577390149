import Component from 'gia/Component';
import SwupGiaPlugin from '@swup/gia-plugin'

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import amvacMarqueeImageMain from './assets/img/amvac-marquee--main.webp'
import amvacMarqueeImageResponsive from './assets/img/amvac-marquee--responsive.webp'

import amvacGridVideoOneMain from './assets/img/amvac-grid-one--main.mp4'
import amvacGridVideoOneResponsive from './assets/img/amvac-grid-one--responsive.mp4'

import amvacGridImageTwoMain from './assets/img/amvac-grid-two--main.webp'
import amvacGridImageTwoResponsive from './assets/img/amvac-grid-two--responsive.webp'

import amvacGridImageThreeMain from './assets/img/amvac-grid-three--main.webp'
import amvacGridImageThreeResponsive from './assets/img/amvac-grid-three--responsive.webp'

import amvacGridImageFourMain from './assets/img/amvac-grid-four--main.webp'
import amvacGridImageFourResponsive from './assets/img/amvac-grid-four--responsive.webp'

import amvacGridImageFiveMain from './assets/img/amvac-grid-five--main.webp'
import amvacGridImageFiveResponsive from './assets/img/amvac-grid-five--responsive.webp'

import amvacGridVideoSixMain from './assets/img/amvac-grid-six--main.mp4'
import amvacGridVideoSixResponsive from './assets/img/amvac-grid-six--responsive.mp4'

import amvacMobileImageOneMain from './assets/img/amvac-mobile-one--main.webp'
import amvacMobileImageOneResponsive from './assets/img/amvac-mobile-one--responsive.webp'

import amvacMobileImageTwoMain from './assets/img/amvac-mobile-two--main.webp'
import amvacMobileImageTwoResponsive from './assets/img/amvac-mobile-two--responsive.webp'

import amvacMobileImageThreeMain from './assets/img/amvac-mobile-three--main.webp'
import amvacMobileImageThreeResponsive from './assets/img/amvac-mobile-three--responsive.webp'

new SwupGiaPlugin({log: false})

export default class AmvacImages extends Component {

  mount() {
    if (document.querySelector('.amvac')) {
      // MARQUEE
      const amvacMarqueeMain = document.querySelector('.amvac .marquee--portfolio__images .marquee--portfolio__image--main')
      amvacMarqueeMain.setAttribute("srcset", amvacMarqueeImageMain)
      const amvacMarqueeResponsive = document.querySelector('.amvac .marquee--portfolio__images .marquee--portfolio__image--responsive')
      amvacMarqueeResponsive.setAttribute("srcset", amvacMarqueeImageResponsive)

      // GRID
      /* const amvacGridOneMainFallback = document.querySelector('.grid__image--one.main')
      amvacGridOneMainFallback.setAttribute("data-poster", amvacGridImageOneMain) */
      const amvacGridOneMainVideo = document.querySelector('.amvac .grid__image--one.main source')
      amvacGridOneMainVideo.setAttribute("data-src", amvacGridVideoOneMain)
      /* const amvacGridOneResponsiveFallback = document.querySelector('.grid__image--one.responsive')
      amvacGridOneResponsiveFallback.setAttribute("data-poster", amvacGridImageOneResponsive) */
      const amvacGridOneResponsiveVideo = document.querySelector('.amvac .grid__image--one.responsive source')
      amvacGridOneResponsiveVideo.setAttribute("data-src", amvacGridVideoOneResponsive)

      const amvacGridTwoMain = document.querySelector('.amvac .grid__image--two .grid__source--main')
      amvacGridTwoMain.setAttribute("srcset", amvacGridImageTwoMain)
      const amvacGridTwoResponsive = document.querySelectorAll('.amvac .grid__image--two .grid__source--responsive')
      amvacGridTwoResponsive.forEach((responsiveItem) => {
        responsiveItem.setAttribute("srcset", amvacGridImageTwoResponsive)
      });

      const amvacGridThreeMain = document.querySelector('.amvac .grid__image--three .grid__source--main')
      amvacGridThreeMain.setAttribute("srcset", amvacGridImageThreeMain)
      const amvacGridThreeResponsive = document.querySelectorAll('.amvac .grid__image--three .grid__source--responsive')
      amvacGridThreeResponsive.forEach((responsiveItem) => {
        responsiveItem.setAttribute("srcset", amvacGridImageThreeResponsive)
      });

      const amvacGridFourMain = document.querySelector('.amvac .grid__image--four .grid__source--main')
      amvacGridFourMain.setAttribute("srcset", amvacGridImageFourMain)
      const amvacGridFourResponsive = document.querySelectorAll('.amvac .grid__image--four .grid__source--responsive')
      amvacGridFourResponsive.forEach((responsiveItem) => {
        responsiveItem.setAttribute("srcset", amvacGridImageFourResponsive)
      });

      const amvacGridFiveMain = document.querySelector('.amvac .grid__image--five .grid__source--main')
      amvacGridFiveMain.setAttribute("srcset", amvacGridImageFiveMain)
      const amvacGridFiveResponsive = document.querySelectorAll('.amvac .grid__image--five .grid__source--responsive')
      amvacGridFiveResponsive.forEach((responsiveItem) => {
        responsiveItem.setAttribute("srcset", amvacGridImageFiveResponsive)
      });

      const amvacGridSixMainVideo = document.querySelector('.amvac .grid__image--six.main source')
      amvacGridSixMainVideo.setAttribute("data-src", amvacGridVideoSixMain)
      /* const amvacGridSixMainPlaceholder = document.querySelector('.amvac .grid__image--six img')
      amvacGridSixMainPlaceholder.setAttribute("srcset", amvacGridImageSixMainPlaceholder) */
      const amvacGridSixResponsiveVideo = document.querySelector('.amvac .grid__image--six.responsive source')
      amvacGridSixResponsiveVideo.setAttribute("data-src", amvacGridVideoSixResponsive)

      // MOBILE GALLERY
      const amvacMobileOneMain = document.querySelector('.amvac .mobile__images--one .mobile__image--main')
      amvacMobileOneMain.setAttribute("srcset", amvacMobileImageOneMain)
      const amvacMobileOneResponsive = document.querySelector('.amvac .mobile__images--one .mobile__image--responsive')
      amvacMobileOneResponsive.setAttribute("srcset", amvacMobileImageOneResponsive)

      const amvacMobileTwoMain = document.querySelector('.amvac .mobile__images--two .mobile__image--main')
      amvacMobileTwoMain.setAttribute("srcset", amvacMobileImageTwoMain)
      const amvacMobileTwoResponsive = document.querySelector('.amvac .mobile__images--two .mobile__image--responsive')
      amvacMobileTwoResponsive.setAttribute("srcset", amvacMobileImageTwoResponsive)

      const amvacMobileThreeMain = document.querySelector('.amvac .mobile__images--three .mobile__image--main')
      amvacMobileThreeMain.setAttribute("srcset", amvacMobileImageThreeMain)
      const amvacMobileThreeResponsive = document.querySelector('.amvac .mobile__images--three .mobile__image--responsive')
      amvacMobileThreeResponsive.setAttribute("srcset", amvacMobileImageThreeResponsive)
    }
  }
}