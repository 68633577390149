
import AmvacImages from '../amvac-images.js';
import KochAgImages from '../koch-ag-images.js';
import MemorialcareImages from '../memorialcare-images.js';
import MillerChildrensImages from '../miller-childrens-images.js';
import NebraskaPublicMediaImages from '../nebraska-public-media-images.js';
import RansomesImages from '../ransomes-images.js';
import MoreImages from '../more-images.js';
import GridGallery from './grid/_grid.js';
import FullWidthImages from './full-width-image/_full-width-image.js';
import DigAds from './dig-ads/_dig-ads.js';
import MobileGallery from './mobile/_mobile.js';
import HomeMarqueeAnimation from './marquees/_marquee--home.js';
import PortfolioMarqueeAnimation from './marquees/_marquee--portfolio.js';

const components = {
  AmvacImages,
  KochAgImages,
  MemorialcareImages,
  MillerChildrensImages,
  NebraskaPublicMediaImages,
  RansomesImages,
  MoreImages,
  GridGallery,
  FullWidthImages,
  DigAds,
  MobileGallery,
  HomeMarqueeAnimation,
  PortfolioMarqueeAnimation,
}

export default components;
