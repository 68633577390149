/* --------------------------
* IMPORTS
* -------------------------- */
import Component from 'gia/Component';


/* --------------------------
* INIT
* -------------------------- */
export default class FullWidthImages extends Component {
  mount() {
    const fullWidthImagesGallery = document.querySelector('.full-width-images')
    let currentFullWidthVideo;

    if (fullWidthImagesGallery) {
      const fullWidthImagesVideos = document.querySelectorAll('.full-width-images__images--video');
      fullWidthImagesVideos.forEach((fullWidthImagesVideo) => {
        fullWidthImagesVideo.addEventListener('click', (e) => {
          e.preventDefault();
          if (window.matchMedia('(max-width: 600px)').matches) {
            currentFullWidthVideo = fullWidthImagesVideo.querySelector('.responsive')
          } else {
            currentFullWidthVideo = fullWidthImagesVideo.querySelector('.main')
          }
          if (fullWidthImagesVideo.classList.contains('paused')) {
            currentFullWidthVideo.play()
            fullWidthImagesVideo.classList.remove('paused')
            fullWidthImagesVideo.classList.add('play')
          } else {
            currentFullWidthVideo.pause()
            fullWidthImagesVideo.classList.remove('play')
            fullWidthImagesVideo.classList.add('paused')
          }
        })
        fullWidthImagesVideo.querySelector('.full-width-images__image').addEventListener('ended', hasEnded, false);
        function hasEnded() {
          if (fullWidthImagesVideo.classList.contains('play')) {
            fullWidthImagesVideo.classList.remove('play')
            fullWidthImagesVideo.classList.add('paused')
          }
        }
      })
    }
  }
}