import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import kochAgMarqueeImageMain from './assets/img/koch-ag-marquee--main.webp'
import kochAgMarqueeImageResponsive from './assets/img/koch-ag-marquee--responsive.webp'

import kochAgGridVideoOneMain from './assets/img/koch-ag-grid-one--main.mp4'
import kochAgGridVideoOneResponsive from './assets/img/koch-ag-grid-one--responsive.mp4'

import kochAgGridImageTwoMain from './assets/img/koch-ag-grid-two--main.webp'
import kochAgGridImageTwoResponsive from './assets/img/koch-ag-grid-two--responsive.webp'

import kochAgGridImageThreeMain from './assets/img/koch-ag-grid-three--main.webp'
import kochAgGridImageThreeResponsive from './assets/img/koch-ag-grid-three--responsive.webp'

import kochAgGridImageFourMain from './assets/img/koch-ag-grid-four--main.webp'
import kochAgGridImageFourResponsive from './assets/img/koch-ag-grid-four--responsive.webp'

import kochAgGridImageFiveMain from './assets/img/koch-ag-grid-five--main.webp'
import kochAgGridImageFiveResponsive from './assets/img/koch-ag-grid-five--responsive.webp'

import kochAgGridVideoSixMain from './assets/img/koch-ag-grid-six--main.mp4'
import kochAgGridVideoSixResponsive from './assets/img/koch-ag-grid-six--responsive.mp4'

import kochAgMobileImageOneMain from './assets/img/koch-ag-mobile-one--main.webp'
import kochAgMobileImageOneResponsive from './assets/img/koch-ag-mobile-one--responsive.webp'

import kochAgMobileImageTwoMain from './assets/img/koch-ag-mobile-two--main.webp'
import kochAgMobileImageTwoResponsive from './assets/img/koch-ag-mobile-two--responsive.webp'

import kochAgMobileImageThreeMain from './assets/img/koch-ag-mobile-three--main.webp'
import kochAgMobileImageThreeResponsive from './assets/img/koch-ag-mobile-three--responsive.webp'


export default class KochAgImages extends Component {

  mount() {
    // MARQUEE
    const kochAgMarqueeMain = document.querySelector('.koch-ag .marquee--portfolio__images .marquee--portfolio__image--main')
    kochAgMarqueeMain.setAttribute("srcset", kochAgMarqueeImageMain)
    const kochAgMarqueeResponsive = document.querySelector('.koch-ag .marquee--portfolio__images .marquee--portfolio__image--responsive')
    kochAgMarqueeResponsive.setAttribute("srcset", kochAgMarqueeImageResponsive)

    // GRID
    /* const kochAgGridOneMainFallback = document.querySelector('.grid__image--one.main')
    kochAgGridOneMainFallback.setAttribute("data-poster", kochAgGridImageOneMain) */
    const kochAgGridOneMainVideo = document.querySelector('.koch-ag .grid__image--one.main source')
    kochAgGridOneMainVideo.setAttribute("data-src", kochAgGridVideoOneMain)
    /* const kochAgGridOneResponsiveFallback = document.querySelector('.grid__image--one.responsive')
    kochAgGridOneResponsiveFallback.setAttribute("data-poster", kochAgGridImageOneResponsive) */
    const kochAgGridOneResponsiveVideo = document.querySelector('.koch-ag .grid__image--one.responsive source')
    kochAgGridOneResponsiveVideo.setAttribute("data-src", kochAgGridVideoOneResponsive)

    const kochAgGridTwoMain = document.querySelector('.koch-ag .grid__image--two .grid__source--main')
    kochAgGridTwoMain.setAttribute("srcset", kochAgGridImageTwoMain)
    const kochAgGridTwoResponsive = document.querySelectorAll('.koch-ag .grid__image--two .grid__source--responsive')
    kochAgGridTwoResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", kochAgGridImageTwoResponsive)
    });

    const kochAgGridThreeMain = document.querySelector('.koch-ag .grid__image--three .grid__source--main')
    kochAgGridThreeMain.setAttribute("srcset", kochAgGridImageThreeMain)
    const kochAgGridThreeResponsive = document.querySelectorAll('.koch-ag .grid__image--three .grid__source--responsive')
    kochAgGridThreeResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", kochAgGridImageThreeResponsive)
    });

    const kochAgGridFourMain = document.querySelector('.koch-ag .grid__image--four .grid__source--main')
    kochAgGridFourMain.setAttribute("srcset", kochAgGridImageFourMain)
    const kochAgGridFourResponsive = document.querySelectorAll('.koch-ag .grid__image--four .grid__source--responsive')
    kochAgGridFourResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", kochAgGridImageFourResponsive)
    });

    const kochAgGridFiveMain = document.querySelector('.koch-ag .grid__image--five .grid__source--main')
    kochAgGridFiveMain.setAttribute("srcset", kochAgGridImageFiveMain)
    const kochAgGridFiveResponsive = document.querySelectorAll('.koch-ag .grid__image--five .grid__source--responsive')
    kochAgGridFiveResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", kochAgGridImageFiveResponsive)
    });

    const kochAgGridSixMainVideo = document.querySelector('.koch-ag .grid__image--six.main source')
    kochAgGridSixMainVideo.setAttribute("data-src", kochAgGridVideoSixMain)
    const kochAgGridSixResponsiveVideo = document.querySelector('.koch-ag .grid__image--six.responsive source')
    kochAgGridSixResponsiveVideo.setAttribute("data-src", kochAgGridVideoSixResponsive)

    // MOBILE GALLERY
    const kochAgMobileOneMain = document.querySelector('.koch-ag .mobile__images--one .mobile__image--main')
    kochAgMobileOneMain.setAttribute("srcset", kochAgMobileImageOneMain)
    const kochAgMobileOneResponsive = document.querySelector('.koch-ag .mobile__images--one .mobile__image--responsive')
    kochAgMobileOneResponsive.setAttribute("srcset", kochAgMobileImageOneResponsive)

    const kochAgMobileTwoMain = document.querySelector('.koch-ag .mobile__images--two .mobile__image--main')
    kochAgMobileTwoMain.setAttribute("srcset", kochAgMobileImageTwoMain)
    const kochAgMobileTwoResponsive = document.querySelector('.koch-ag .mobile__images--two .mobile__image--responsive')
    kochAgMobileTwoResponsive.setAttribute("srcset", kochAgMobileImageTwoResponsive)

    const kochAgMobileThreeMain = document.querySelector('.koch-ag .mobile__images--three .mobile__image--main')
    kochAgMobileThreeMain.setAttribute("srcset", kochAgMobileImageThreeMain)
    const kochAgMobileThreeResponsive = document.querySelector('.koch-ag .mobile__images--three .mobile__image--responsive')
    kochAgMobileThreeResponsive.setAttribute("srcset", kochAgMobileImageThreeResponsive)
  }
}