import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import ransomesMarqueeImageMain from './assets/img/ransomes-marquee--main.webp'
import ransomesMarqueeImageResponsive from './assets/img/ransomes-marquee--responsive.webp'

import ransomesGridImageOneMain from './assets/img/ransomes-grid-one--main.webp'
import ransomesGridImageOneResponsive from './assets/img/ransomes-grid-one--responsive.webp'

import ransomesGridImageTwoMain from './assets/img/ransomes-grid-two--main.webp'
import ransomesGridImageTwoResponsive from './assets/img/ransomes-grid-two--responsive.webp'

import ransomesGridImageThreeMain from './assets/img/ransomes-grid-three--main.webp'
import ransomesGridImageThreeResponsive from './assets/img/ransomes-grid-three--responsive.webp'

import ransomesGridImageFourMain from './assets/img/ransomes-grid-four--main.webp'
import ransomesGridImageFourResponsive from './assets/img/ransomes-grid-four--responsive.webp'

import ransomesGridImageFiveMain from './assets/img/ransomes-grid-five--main.webp'
import ransomesGridImageFiveResponsive from './assets/img/ransomes-grid-five--responsive.webp'

import ransomesGridImageSixMain from './assets/img/ransomes-grid-six--main.webp'
import ransomesGridImageSixResponsive from './assets/img/ransomes-grid-six--responsive.webp'

import ransomesMobileImageOneMain from './assets/img/ransomes-mobile-one--main.webp'
import ransomesMobileImageOneResponsive from './assets/img/ransomes-mobile-one--responsive.webp'

import ransomesMobileImageTwoMain from './assets/img/ransomes-mobile-two--main.webp'
import ransomesMobileImageTwoResponsive from './assets/img/ransomes-mobile-two--responsive.webp'

import ransomesMobileImageThreeMain from './assets/img/ransomes-mobile-three--main.webp'
import ransomesMobileImageThreeResponsive from './assets/img/ransomes-mobile-three--responsive.webp'


export default class RansomesImages extends Component {

  mount() {
    // MARQUEE
    const ransomesMarqueeMain = document.querySelector('.ransomes .marquee--portfolio__images .marquee--portfolio__image--main')
    ransomesMarqueeMain.setAttribute("srcset", ransomesMarqueeImageMain)
    const ransomesMarqueeResponsive = document.querySelector('.ransomes .marquee--portfolio__images .marquee--portfolio__image--responsive')
    ransomesMarqueeResponsive.setAttribute("srcset", ransomesMarqueeImageResponsive)

    // GRID
    const ransomesGridOneMain = document.querySelector('.ransomes .grid__image--one .grid__source--main')
    ransomesGridOneMain.setAttribute("srcset", ransomesGridImageOneMain)
    const ransomesGridOneResponsive = document.querySelectorAll('.ransomes .grid__image--one .grid__source--responsive')
    ransomesGridOneResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageOneResponsive)
    });

    const ransomesGridTwoMain = document.querySelector('.ransomes .grid__image--two .grid__source--main')
    ransomesGridTwoMain.setAttribute("srcset", ransomesGridImageTwoMain)
    const ransomesGridTwoResponsive = document.querySelectorAll('.ransomes .grid__image--two .grid__source--responsive')
    ransomesGridTwoResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageTwoResponsive)
    });

    const ransomesGridThreeMain = document.querySelector('.ransomes .grid__image--three .grid__source--main')
    ransomesGridThreeMain.setAttribute("srcset", ransomesGridImageThreeMain)
    const ransomesGridThreeResponsive = document.querySelectorAll('.ransomes .grid__image--three .grid__source--responsive')
    ransomesGridThreeResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageThreeResponsive)
    });

    const ransomesGridFourMain = document.querySelector('.ransomes .grid__image--four .grid__source--main')
    ransomesGridFourMain.setAttribute("srcset", ransomesGridImageFourMain)
    const ransomesGridFourResponsive = document.querySelectorAll('.ransomes .grid__image--four .grid__source--responsive')
    ransomesGridFourResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageFourResponsive)
    });

    const ransomesGridFiveMain = document.querySelector('.ransomes .grid__image--five .grid__source--main')
    ransomesGridFiveMain.setAttribute("srcset", ransomesGridImageFiveMain)
    const ransomesGridFiveResponsive = document.querySelectorAll('.ransomes .grid__image--five .grid__source--responsive')
    ransomesGridFiveResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageFiveResponsive)
    });

    const ransomesGridSixMain = document.querySelector('.ransomes .grid__image--six .grid__source--main')
    ransomesGridSixMain.setAttribute("srcset", ransomesGridImageSixMain)
    const ransomesGridSixResponsive = document.querySelectorAll('.ransomes .grid__image--six .grid__source--responsive')
    ransomesGridSixResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", ransomesGridImageSixResponsive)
    });

    // MOBILE GALLERY
    const ransomesMobileOneMain = document.querySelector('.ransomes .mobile__images--one .mobile__image--main')
    ransomesMobileOneMain.setAttribute("srcset", ransomesMobileImageOneMain)
    const ransomesMobileOneResponsive = document.querySelector('.ransomes .mobile__images--one .mobile__image--responsive')
    ransomesMobileOneResponsive.setAttribute("srcset", ransomesMobileImageOneResponsive)

    const ransomesMobileTwoMain = document.querySelector('.ransomes .mobile__images--two .mobile__image--main')
    ransomesMobileTwoMain.setAttribute("srcset", ransomesMobileImageTwoMain)
    const ransomesMobileTwoResponsive = document.querySelector('.ransomes .mobile__images--two .mobile__image--responsive')
    ransomesMobileTwoResponsive.setAttribute("srcset", ransomesMobileImageTwoResponsive)

    const ransomesMobileThreeMain = document.querySelector('.ransomes .mobile__images--three .mobile__image--main')
    ransomesMobileThreeMain.setAttribute("srcset", ransomesMobileImageThreeMain)
    const ransomesMobileThreeResponsive = document.querySelector('.ransomes .mobile__images--three .mobile__image--responsive')
    ransomesMobileThreeResponsive.setAttribute("srcset", ransomesMobileImageThreeResponsive)
  }
}