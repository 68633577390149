/* --------------------------
* IMPORTS
* -------------------------- */
import { gsap, ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);


/* --------------------------
* VARIABLES
* -------------------------- */
let showHeaderAnimation;

/* --------------------------
* INIT
* -------------------------- */
function init() {
  const showHeaderAnimation = gsap.from('.site-header', {
    yPercent: -100,
    paused: true,
    duration: 0.2,
  }).progress(1)

  ScrollTrigger.create({
    start: 'top top',
    end: 99999,
    toggleClass: { className: 'site-header--scrolled', targets: 'body' },
  })

  ScrollTrigger.create({
    start: 'top -200',
    end: 99999,
    onUpdate: (self) => {
      if (self.direction === -1) {
        showHeaderAnimation.play();
      } else {
        showHeaderAnimation.reverse();
      }
    },
  });
}

init();

export default {
  init,
};
