/* --------------------------
* IMPORTS
* -------------------------- */
import { gsap } from 'gsap/all';
import * as THREE from 'three'
import toonTexture from '../../assets/img/toon.jpg'
import millerChildrensToonTexture from '../../assets/img/millerchildrens.jpg'
import memorialcareToonTexture from '../../assets/img/mc.jpg'
import kochAgToonTexture from '../../assets/img/kochag.jpg'
import ransomesToonTexture from '../../assets/img/ransomes.jpg'
import amvacToonTexture from '../../assets/img/amvac.jpg'
import nebraskaPublicMediaToonTexture from '../../assets/img/nebraskapublicmedia.jpg'
import moreToonTexture from '../../assets/img/more.jpg'
import { AdditiveBlending } from 'three';


/* --------------------------
 * DEFINE CONSTANTS
 * -------------------------- */
const siteHeader = document.querySelector('.site-header')
const menuTrigger = document.querySelector('.site-header__menu-trigger')
const menuDropdown = document.querySelector('.site-header__dropdown')
const homeLink = document.querySelector('.site-header__logo')
const menuLinks = document.querySelector('.site-header__dropdown-links')
const menuItems = document.querySelectorAll('.site-header__dropdown-link')

let headerHeight;
// const canvas = document.querySelector('.webgl')

gsap.set('.site-header__dropdown', {
  autoAlpha: 0,
  clipPath: "inset(0% 0% 100% 0%)"
})
gsap.set('canvas.menu', {
  autoAlpha: 0,
})
gsap.set(menuItems, {
  autoAlpha: 0,
  y: 50,
  rotateX: -45,
  transformOrigin: "0% 0%"
})
gsap.set('.site-header__menu-trigger--close, .site-header__close-icon', {
  clipPath: "inset(0% 0% 100% 0%)",
  autoAlpha: 0,
  y: 20,
  rotateX: -45,
  transformOrigin: "0% 0%"
})
const showDropdownAnimation = gsap.to('.site-header__dropdown', {
  clipPath: "inset(0% 0% 0% 0%)",
  // y: 0,
  // autoAlpha: 1,
  paused: true,
  duration: 0.5,
  ease: 'power3.easeOut',
}).progress(0)
const showCanvasAnimation = gsap.to('canvas.menu', {
  // clipPath:"inset(0% 0% 0% 0%)",
  autoAlpha: 1,
  paused: true,
  duration: 0.5,
  ease: 'power3.easeOut',
}).progress(0)
const menuOpenTriggerAnimation = gsap.to('.site-header__menu-trigger--open, .site-header__menu-icon', {
  clipPath: "inset(100% 0% 0% 0%)",
  autoAlpha: 0,
  rotateX: 45,
  y: -20,
  paused: true,
  stagger: 0.05,
  duration: 0.6,
  ease: 'power2.easeOut',
}).progress(0)
const menuCloseTriggerAnimation = gsap.to('.site-header__menu-trigger--close, .site-header__close-icon', {
  clipPath: "inset(0% 0% 0% 0%)",
  y: 0,
  rotateX: 0,
  autoAlpha: 1,
  paused: true,
  stagger: 0.05,
  duration: 0.6,
  ease: 'power2.easeOut',
}).progress(0)
const showMenuItemsAnimation = gsap.to(menuItems, {
  y: 0,
  rotateX: 0,
  autoAlpha: 1,
  paused: true,
  stagger: 0.05,
  duration: 0.35,
  ease: 'power2.easeOut',
}).progress(0)

init();


/* --------------------------
* THREE
* -------------------------- */
const parameters = {
  mainMaterialColor: '#ffeded'
}

/**
 * Base
 */
// Canvas
const canvasMenu = document.querySelector('canvas.menu')

// Scene
const scene = new THREE.Scene()

/**
 * Objects
 */
// Texture
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load(toonTexture)
const memorialcareTexture = textureLoader.load(memorialcareToonTexture)
const millerChildrensTexture = textureLoader.load(millerChildrensToonTexture)
const kochAgTexture = textureLoader.load(kochAgToonTexture)
const ransomesTexture = textureLoader.load(ransomesToonTexture)
const amvacTexture = textureLoader.load(amvacToonTexture)
const nebraskaPublicMediaTexture = textureLoader.load(nebraskaPublicMediaToonTexture)
const moreTexture = textureLoader.load(moreToonTexture)

// Material
const material = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: gradientTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false,
})
const millerChildrensMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: millerChildrensTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const memorialcareMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: memorialcareTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const kochAgMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: kochAgTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const ransomesMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: ransomesTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const amvacMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: amvacTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const nebraskaPublicMediaMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: nebraskaPublicMediaTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
const moreMaterial = new THREE.MeshToonMaterial({
  color: parameters.mainMaterialColor,
  gradientMap: moreTexture,
  blending: AdditiveBlending,
  wireframe: true,
  depthWrite: false,
  depthTest: false
})
material.needsUpdate = true
millerChildrensMaterial.needsUpdate = true
memorialcareMaterial.needsUpdate = true
kochAgMaterial.needsUpdate = true
ransomesMaterial.needsUpdate = true
amvacMaterial.needsUpdate = true
nebraskaPublicMediaMaterial.needsUpdate = true
moreMaterial.needsUpdate = true

// Meshes
const mesh1 = new THREE.Mesh(
  new THREE.TorusGeometry(1.15, 0.46, 16, 24),
  material
)
let meshPositionY
let meshPositionX

if (window.matchMedia('(min-width: 769px)').matches) {
  meshPositionY = 0
  meshPositionX = 1.6
} else if (window.matchMedia('(max-width: 768px)').matches) {
  meshPositionY = - 1
  meshPositionX = 0.8
  mesh1.scale.set(1.2, 1.2, 1.2)
}

mesh1.position.y = meshPositionY
mesh1.position.x = meshPositionX

scene.add(mesh1)

const sectionMeshes = [mesh1]


/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)


/**
 * Sizes
 */
headerHeight = siteHeader.offsetHeight

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight - headerHeight
}

canvasMenu.style.top = headerHeight + 'px'

window.addEventListener('resize', () => {
  headerHeight = siteHeader.offsetHeight
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight - headerHeight

  canvasMenu.style.top = headerHeight + 'px'

  // Update Mesh
  if (window.matchMedia('(min-width: 769px)').matches) {
    meshPositionY = 0
    meshPositionX = 1.6
    mesh1.scale.set(1, 1, 1)
  } else if (window.matchMedia('(max-width: 768px)').matches) {
    meshPositionY = - 1
    meshPositionX = 0.8
    mesh1.scale.set(1.2, 1.2, 1.2)
  }
  mesh1.position.y = meshPositionY
  mesh1.position.x = meshPositionX

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvasMenu,
  alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


Array.from(menuItems).forEach((menuItem) => {
  menuItem.addEventListener('mouseenter', (e) => {
    e.preventDefault();

    const activeLink = menuItem.getAttribute('data-attribute');

    if (activeLink === "Miller Children's") {
      mesh1.material = millerChildrensMaterial
    } else if (activeLink === 'MemorialCare') {
      mesh1.material = memorialcareMaterial
    } else if (activeLink === 'Koch Ag') {
      mesh1.material = kochAgMaterial
    } else if (activeLink === 'Ransomes') {
      mesh1.material = ransomesMaterial
    } else if (activeLink === 'AMVAC') {
      mesh1.material = amvacMaterial
    } else if (activeLink === 'Nebraska Public Media') {
      mesh1.material = nebraskaPublicMediaMaterial
    } else if (activeLink === 'More') {
      mesh1.material = moreMaterial
    }
  })
})

menuLinks.addEventListener('mouseleave', (e) => {
  e.preventDefault();
  mesh1.material = material
})


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
let rotatingAnimation

const tick = () => {
  const elapsedTime = clock.getElapsedTime()
  const deltaTime = elapsedTime - previousTime
  previousTime = elapsedTime

  // Animate meshes
  mesh1.rotation.x += deltaTime * 0.1
  mesh1.rotation.y += deltaTime * 0.12

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  rotatingAnimation = window.requestAnimationFrame(tick)
}

// tick();

/* --------------------------
* INIT
* -------------------------- */
function init() {
  menuTrigger.addEventListener('click', (e) => {
    e.preventDefault();
    menuTrigger.blur();
    toggleMenu();
  });

  homeLink.addEventListener('click', (e) => {
    if (document.body.classList.contains('dropdown-active') && document.body.querySelector('.marquee--home')) {
      e.preventDefault();
      toggleMenu();
    } else if (document.body.classList.contains('dropdown-active') && !document.body.querySelector('.marquee--home')) {
      gsap.delayedCall(1, () => closeMenu());
    }
  });

  menuItems.forEach(menuItem => {
    menuItem.addEventListener('click', (e) => {

      const activeLink = menuItem.getAttribute('data-attribute');

      if (activeLink === "Miller Children's" && document.body.querySelector('.miller-childrens')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'MemorialCare' && document.body.querySelector('.memorialcare')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'Koch Ag' && document.body.querySelector('.koch-ag')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'Ransomes' && document.body.querySelector('.ransomes')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'AMVAC' && document.body.querySelector('.amvac')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'Nebraska Public Media' && document.body.querySelector('.nebraska-public-media')) {
        e.preventDefault();
        toggleMenu();
      } else if (activeLink === 'MOre' && document.body.querySelector('.more')) {
        e.preventDefault();
        toggleMenu();
      } else {
        gsap.delayedCall(1, () => closeMenu());
      }
    })
  })
}

function toggleMenu() {
  document.body.classList.toggle('dropdown-active');
  menuDropdown.classList.toggle('site-header__dropdown--active');

  if (menuDropdown.classList.contains('site-header__dropdown--active')) {
    scene.add(mesh1);
    tick();
    clock.start();
    gsap.set('canvas.menu', {
      autoAlpha: 0
    })
    menuOpenTriggerAnimation.timeScale(1).play();
    menuCloseTriggerAnimation.timeScale(1).play();
    gsap.delayedCall(0.2, () => gsap.set('.site-header__dropdown', { autoAlpha: 1 }));
    gsap.delayedCall(0.2, () => showDropdownAnimation.timeScale(1).play());
    gsap.delayedCall(0.4, () => showMenuItemsAnimation.timeScale(1).play());
    gsap.delayedCall(0.4, () => showCanvasAnimation.timeScale(1).play());
  } else {
    showDropdownAnimation.timeScale(1).reverse();
    showCanvasAnimation.timeScale(1).reverse();
    showMenuItemsAnimation.timeScale(1).reverse();
    menuOpenTriggerAnimation.timeScale(1).reverse();
    menuCloseTriggerAnimation.timeScale(1).reverse();
    gsap.delayedCall(0.6, () => gsap.set('.site-header__dropdown', { autoAlpha: 0 }));
    gsap.delayedCall(0.6, () => sceneReset());
  }
}

function closeMenu() {
  document.body.classList.remove('dropdown-active');
  menuDropdown.classList.remove('site-header__dropdown--active');
  showCanvasAnimation.timeScale(2).reverse();
  showDropdownAnimation.timeScale(2).reverse(0);
  showMenuItemsAnimation.timeScale(2).reverse(0);
  menuOpenTriggerAnimation.timeScale(2).reverse(0);
  menuCloseTriggerAnimation.timeScale(2).reverse(0);
  gsap.delayedCall(1, () => gsap.set('.site-header__dropdown', { autoAlpha: 0 }));
  sceneReset();
}

function sceneReset() {
  mesh1.material.dispose();
  scene.remove(mesh1);
  clock.stop();
  cancelAnimationFrame(rotatingAnimation);
  mesh1.rotation.x = 0
  mesh1.rotation.y = 0
}


export default {
  init,
};
