import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import nebraskaPublicMediaMarqueeImageMain from './assets/img/nepm-marquee--main.webp'
import nebraskaPublicMediaMarqueeImageResponsive from './assets/img/nepm-marquee--responsive.webp'

import nebraskaPublicMediaGridImageOneMain from './assets/img/nepm-grid-one--main.webp'
import nebraskaPublicMediaGridImageOneResponsive from './assets/img/nepm-grid-one--responsive.webp'

import nebraskaPublicMediaGridImageTwoMain from './assets/img/nepm-grid-two--main.webp'
import nebraskaPublicMediaGridImageTwoResponsive from './assets/img/nepm-grid-two--responsive.webp'

import nebraskaPublicMediaGridImageThreeMain from './assets/img/nepm-grid-three--main.webp'
import nebraskaPublicMediaGridImageThreeResponsive from './assets/img/nepm-grid-three--responsive.webp'

import nebraskaPublicMediaGridImageFourMain from './assets/img/nepm-grid-four--main.webp'
import nebraskaPublicMediaGridImageFourResponsive from './assets/img/nepm-grid-four--responsive.webp'

import nebraskaPublicMediaGridImageFiveMain from './assets/img/nepm-grid-five--main.webp'
import nebraskaPublicMediaGridImageFiveResponsive from './assets/img/nepm-grid-five--responsive.webp'

import nebraskaPublicMediaGridImageSixMain from './assets/img/nepm-grid-six--main.webp'
import nebraskaPublicMediaGridImageSixResponsive from './assets/img/nepm-grid-six--responsive.webp'

import nebraskaPublicMediaMobileImageOneMain from './assets/img/nepm-mobile-one--main.webp'
import nebraskaPublicMediaMobileImageOneResponsive from './assets/img/nepm-mobile-one--responsive.webp'

import nebraskaPublicMediaMobileImageTwoMain from './assets/img/nepm-mobile-two--main.webp'
import nebraskaPublicMediaMobileImageTwoResponsive from './assets/img/nepm-mobile-two--responsive.webp'

import nebraskaPublicMediaMobileImageThreeMain from './assets/img/nepm-mobile-three--main.webp'
import nebraskaPublicMediaMobileImageThreeResponsive from './assets/img/nepm-mobile-three--responsive.webp'


export default class NebraskaPublicMediaImages extends Component {

  mount() {
    // MARQUEE
    const nebraskaPublicMediaMarqueeMain = document.querySelector('.nebraska-public-media .marquee--portfolio__images .marquee--portfolio__image--main')
    nebraskaPublicMediaMarqueeMain.setAttribute("srcset", nebraskaPublicMediaMarqueeImageMain)
    const nebraskaPublicMediaMarqueeResponsive = document.querySelector('.nebraska-public-media .marquee--portfolio__images .marquee--portfolio__image--responsive')
    nebraskaPublicMediaMarqueeResponsive.setAttribute("srcset", nebraskaPublicMediaMarqueeImageResponsive)

    // GRID
    const nebraskaPublicMediaGridOneMain = document.querySelector('.nebraska-public-media .grid__image--one .grid__source--main')
    nebraskaPublicMediaGridOneMain.setAttribute("srcset", nebraskaPublicMediaGridImageOneMain)
    const nebraskaPublicMediaGridOneResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--one .grid__source--responsive')
    nebraskaPublicMediaGridOneResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageOneResponsive)
    });

    const nebraskaPublicMediaGridTwoMain = document.querySelector('.nebraska-public-media .grid__image--two .grid__source--main')
    nebraskaPublicMediaGridTwoMain.setAttribute("srcset", nebraskaPublicMediaGridImageTwoMain)
    const nebraskaPublicMediaGridTwoResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--two .grid__source--responsive')
    nebraskaPublicMediaGridTwoResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageTwoResponsive)
    });

    const nebraskaPublicMediaGridThreeMain = document.querySelector('.nebraska-public-media .grid__image--three .grid__source--main')
    nebraskaPublicMediaGridThreeMain.setAttribute("srcset", nebraskaPublicMediaGridImageThreeMain)
    const nebraskaPublicMediaGridThreeResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--three .grid__source--responsive')
    nebraskaPublicMediaGridThreeResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageThreeResponsive)
    });

    const nebraskaPublicMediaGridFourMain = document.querySelector('.nebraska-public-media .grid__image--four .grid__source--main')
    nebraskaPublicMediaGridFourMain.setAttribute("srcset", nebraskaPublicMediaGridImageFourMain)
    const nebraskaPublicMediaGridFourResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--four .grid__source--responsive')
    nebraskaPublicMediaGridFourResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageFourResponsive)
    });

    const nebraskaPublicMediaGridFiveMain = document.querySelector('.nebraska-public-media .grid__image--five .grid__source--main')
    nebraskaPublicMediaGridFiveMain.setAttribute("srcset", nebraskaPublicMediaGridImageFiveMain)
    const nebraskaPublicMediaGridFiveResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--five .grid__source--responsive')
    nebraskaPublicMediaGridFiveResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageFiveResponsive)
    });

    const nebraskaPublicMediaGridSixMain = document.querySelector('.nebraska-public-media .grid__image--six .grid__source--main')
    nebraskaPublicMediaGridSixMain.setAttribute("srcset", nebraskaPublicMediaGridImageSixMain)
    const nebraskaPublicMediaGridSixResponsive = document.querySelectorAll('.nebraska-public-media .grid__image--six .grid__source--responsive')
    nebraskaPublicMediaGridSixResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", nebraskaPublicMediaGridImageSixResponsive)
    });

    // MOBILE GALLERY
    const nebraskaPublicMediaMobileOneMain = document.querySelector('.nebraska-public-media .mobile__images--one .mobile__image--main')
    nebraskaPublicMediaMobileOneMain.setAttribute("srcset", nebraskaPublicMediaMobileImageOneMain)
    const nebraskaPublicMediaMobileOneResponsive = document.querySelector('.nebraska-public-media .mobile__images--one .mobile__image--responsive')
    nebraskaPublicMediaMobileOneResponsive.setAttribute("srcset", nebraskaPublicMediaMobileImageOneResponsive)

    const nebraskaPublicMediaMobileTwoMain = document.querySelector('.nebraska-public-media .mobile__images--two .mobile__image--main')
    nebraskaPublicMediaMobileTwoMain.setAttribute("srcset", nebraskaPublicMediaMobileImageTwoMain)
    const nebraskaPublicMediaMobileTwoResponsive = document.querySelector('.nebraska-public-media .mobile__images--two .mobile__image--responsive')
    nebraskaPublicMediaMobileTwoResponsive.setAttribute("srcset", nebraskaPublicMediaMobileImageTwoResponsive)

    const nebraskaPublicMediaMobileThreeMain = document.querySelector('.nebraska-public-media .mobile__images--three .mobile__image--main')
    nebraskaPublicMediaMobileThreeMain.setAttribute("srcset", nebraskaPublicMediaMobileImageThreeMain)
    const nebraskaPublicMediaMobileThreeResponsive = document.querySelector('.nebraska-public-media .mobile__images--three .mobile__image--responsive')
    nebraskaPublicMediaMobileThreeResponsive.setAttribute("srcset", nebraskaPublicMediaMobileImageThreeResponsive)
  }
}