import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import memorialcareMarqueeImageMain from './assets/img/memorialcare-marquee--main.webp'
import memorialcareMarqueeImageResponsive from './assets/img/memorialcare-marquee--responsive.webp'

import memorialcareGridVideoOneMain from './assets/img/memorialcare-grid-six--main.mp4'
import memorialcareGridVideoOneResponsive from './assets/img/memorialcare-grid-six--responsive.mp4'

import memorialcareGridImageTwoMain from './assets/img/memorialcare-grid-five--main.webp'
import memorialcareGridImageTwoResponsive from './assets/img/memorialcare-grid-five--responsive.webp'

import memorialcareGridImageThreeMain from './assets/img/memorialcare-grid-four--main.webp'
import memorialcareGridImageThreeResponsive from './assets/img/memorialcare-grid-four--responsive.webp'

import memorialcareGridImageFourMain from './assets/img/memorialcare-grid-three--main.webp'
import memorialcareGridImageFourResponsive from './assets/img/memorialcare-grid-three--responsive.webp'

import memorialcareGridImageFiveMain from './assets/img/memorialcare-grid-two--main.webp'
import memorialcareGridImageFiveResponsive from './assets/img/memorialcare-grid-two--responsive.webp'

import memorialcareGridVideoSixMain from './assets/img/memorialcare-grid-one--main.mp4'
import memorialcareGridVideoSixResponsive from './assets/img/memorialcare-grid-one--responsive.mp4'

import memorialcareMobileImageOneMain from './assets/img/memorialcare-mobile-one--main.webp'
import memorialcareMobileImageOneResponsive from './assets/img/memorialcare-mobile-one--responsive.webp'

import memorialcareMobileImageTwoMain from './assets/img/memorialcare-mobile-two--main.webp'
import memorialcareMobileImageTwoResponsive from './assets/img/memorialcare-mobile-two--responsive.webp'

import memorialcareMobileImageThreeMain from './assets/img/memorialcare-mobile-three--main.webp'
import memorialcareMobileImageThreeResponsive from './assets/img/memorialcare-mobile-three--responsive.webp'

export default class MemorialcareImages extends Component {

  mount() {
    // MARQUEE
    const memorialcareMarqueeMain = document.querySelector('.memorialcare .marquee--portfolio__images .marquee--portfolio__image--main')
    memorialcareMarqueeMain.setAttribute("srcset", memorialcareMarqueeImageMain)
    const memorialcareMarqueeResponsive = document.querySelector('.memorialcare .marquee--portfolio__images .marquee--portfolio__image--responsive')
    memorialcareMarqueeResponsive.setAttribute("srcset", memorialcareMarqueeImageResponsive)

    // GRID
    /* const memorialcareGridOneMainFallback = document.querySelector('.grid__image--one.main')
    memorialcareGridOneMainFallback.setAttribute("data-poster", memorialcareGridImageOneMain) */
    const memorialcareGridOneMainVideo = document.querySelector('.memorialcare .grid__image--one.main source')
    memorialcareGridOneMainVideo.setAttribute("data-src", memorialcareGridVideoOneMain)
    /* const memorialcareGridOneResponsiveFallback = document.querySelector('.grid__image--one.responsive')
    memorialcareGridOneResponsiveFallback.setAttribute("data-poster", memorialcareGridImageOneResponsive) */
    const memorialcareGridOneResponsiveVideo = document.querySelector('.memorialcare .grid__image--one.responsive source')
    memorialcareGridOneResponsiveVideo.setAttribute("data-src", memorialcareGridVideoOneResponsive)

    const memorialcareGridTwoMain = document.querySelector('.memorialcare .grid__image--two .grid__source--main')
    memorialcareGridTwoMain.setAttribute("srcset", memorialcareGridImageTwoMain)
    const memorialcareGridTwoResponsive = document.querySelectorAll('.memorialcare .grid__image--two .grid__source--responsive')
    memorialcareGridTwoResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", memorialcareGridImageTwoResponsive)
    });

    const memorialcareGridThreeMain = document.querySelector('.memorialcare .grid__image--three .grid__source--main')
    memorialcareGridThreeMain.setAttribute("srcset", memorialcareGridImageThreeMain)
    const memorialcareGridThreeResponsive = document.querySelectorAll('.memorialcare .grid__image--three .grid__source--responsive')
    memorialcareGridThreeResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", memorialcareGridImageThreeResponsive)
    });

    const memorialcareGridFourMain = document.querySelector('.memorialcare .grid__image--four .grid__source--main')
    memorialcareGridFourMain.setAttribute("srcset", memorialcareGridImageFourMain)
    const memorialcareGridFourResponsive = document.querySelectorAll('.memorialcare .grid__image--four .grid__source--responsive')
    memorialcareGridFourResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", memorialcareGridImageFourResponsive)
    });

    const memorialcareGridFiveMain = document.querySelector('.memorialcare .grid__image--five .grid__source--main')
    memorialcareGridFiveMain.setAttribute("srcset", memorialcareGridImageFiveMain)
    const memorialcareGridFiveResponsive = document.querySelectorAll('.memorialcare .grid__image--five .grid__source--responsive')
    memorialcareGridFiveResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", memorialcareGridImageFiveResponsive)
    });

    const memorialcareGridSixMainVideo = document.querySelector('.memorialcare .grid__image--six.main source')
    memorialcareGridSixMainVideo.setAttribute("data-src", memorialcareGridVideoSixMain)
    const memorialcareGridSixResponsiveVideo = document.querySelector('.memorialcare .grid__image--six.responsive source')
    memorialcareGridSixResponsiveVideo.setAttribute("data-src", memorialcareGridVideoSixResponsive)

    // MOBILE GALLERY
    const memorialcareMobileOneMain = document.querySelector('.memorialcare .mobile__images--one .mobile__image--main')
    memorialcareMobileOneMain.setAttribute("srcset", memorialcareMobileImageOneMain)
    const memorialcareMobileOneResponsive = document.querySelector('.memorialcare .mobile__images--one .mobile__image--responsive')
    memorialcareMobileOneResponsive.setAttribute("srcset", memorialcareMobileImageOneResponsive)

    const memorialcareMobileTwoMain = document.querySelector('.memorialcare .mobile__images--two .mobile__image--main')
    memorialcareMobileTwoMain.setAttribute("srcset", memorialcareMobileImageTwoMain)
    const memorialcareMobileTwoResponsive = document.querySelector('.memorialcare .mobile__images--two .mobile__image--responsive')
    memorialcareMobileTwoResponsive.setAttribute("srcset", memorialcareMobileImageTwoResponsive)

    const memorialcareMobileThreeMain = document.querySelector('.memorialcare .mobile__images--three .mobile__image--main')
    memorialcareMobileThreeMain.setAttribute("srcset", memorialcareMobileImageThreeMain)
    const memorialcareMobileThreeResponsive = document.querySelector('.memorialcare .mobile__images--three .mobile__image--responsive')
    memorialcareMobileThreeResponsive.setAttribute("srcset", memorialcareMobileImageThreeResponsive)
  }
}
