import './style.scss'
import './01-components/_main.js'
import components from './01-components/_components.js'
import { gsap } from 'gsap'
import lozad from 'lozad'

import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupGiaPlugin from '@swup/gia-plugin'

import loadComponents from 'gia/loadComponents';

/**
 * Touch Device
 */
if (window.matchMedia("(pointer: coarse)").matches) {
  document.body.classList.add('touch')
}

/**
 * Lazy Loading
 */
const observer = lozad('.lozad', {
  rootMargin: '1500px 0px', // syntax similar to that of CSS Margin
  threshold: 0.1, // ratio of element convergence
  enableAutoReload: true, // it will reload the new image when validating attributes changes
  loaded: (el) => {
    el.classList.add('lazy-load--loaded');
  },
});
observer.observe();

/**
 * SWUP
 */
const pageTransitionOptions = [
  {
    from: '(.*)',
    to: '(.*)',
    in: function(next) {
      document.querySelector('#swup').style.opacity = 0;
      const pageTransition = document.querySelector('.page-transition');
      // gsap.to(pageTransition, 0.6, { x: '100%', ease: 'power2.inOut' });
      gsap.set(document.querySelector('#swup'), { opacity: 1 });
      gsap.set(pageTransition, { delay: 1, clipPath: 'inset(0% 0% 100% 0%)'})
      // gsap.to(pageTransition, 0.6, { delay: 0.8, x: '200%', ease: 'power2.inOut', onComplete: next });
    },
    out: (next) => {
      document.querySelector('#swup').style.opacity = 1;
      const pageTransition = document.querySelector('.page-transition');
      gsap.to(pageTransition, 1, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'power4.inOut' });
      gsap.set(document.querySelector('#swup'), { delay: 1.05, opacity: 0 });
      gsap.to(pageTransition, 1, { delay: 1.1, clipPath: 'inset(100% 0% 0% 0%)', ease: 'power4.inOut', onStart: next });
    }
  }
]

// enable components
loadComponents(components);


const swup = new Swup({
  plugins: [
    new SwupJsPlugin(pageTransitionOptions),
    new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: false,
    }),
    // new SwupScriptsPlugin()
  ]
});

// reload components for each container after transition
swup.on('contentReplaced', function () {
  observer.observe();
  document.querySelectorAll('[data-swup]').forEach(function (container) {
      loadComponents(components, container);
  });
});