import Component from 'gia/Component';
import { gsap, ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);
import * as THREE from 'three';
import portfolioVertexShader from '../../assets/shaders/portfolioMarquee/vertex.glsl';
import portfolioFragmentShader from '../../assets/shaders/portfolioMarquee/fragment.glsl';

/**
 * Animate Home Marquee
 */
export default class PortfolioMarqueeAnimation extends Component {
  mount() {
    function init() {
      const hideMarqueeAnimation = gsap.to('.marquee h1, .marquee h2, .marquee p, .marquee a', {
        clipPath:"inset(100% 0% 0% 0%)",
        paused: true,
        duration: 0.45,
        y: -20,
        rotateX: 45,
        autoAlpha: 0,
        ease: 'power2.easeInOut',
        stagger: 0.1,
      }).progress(0)

      document.querySelector('.site-header__menu-trigger').addEventListener('click', (e) => {
        e.preventDefault();
        if (document.body.classList.contains('dropdown-active')) {
          hideMarqueeAnimation.play();
        } else {
          gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
        }
      });

      const menuItems = document.querySelectorAll('.site-header__dropdown-link')

      menuItems.forEach(menuItem => {
        menuItem.addEventListener('click', (e) => {
        
          const activeLink = menuItem.getAttribute('data-attribute');
      
          if (activeLink === "Miller Children's" && document.body.querySelector('.miller-childrens')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'MemorialCare' && document.body.querySelector('.memorialcare')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'Koch Ag' && document.body.querySelector('.koch-ag')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'Ransomes' && document.body.querySelector('.ransomes')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'AMVAC' && document.body.querySelector('.amvac')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'Nebraska Public Media' && document.body.querySelector('.nebraska-public-media')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          } else if (activeLink === 'More' && document.body.querySelector('.more')) {
            e.preventDefault();
            gsap.delayedCall(0.15, () => hideMarqueeAnimation.reverse());
          }
        })
      })
    }
    init();

    // Image animation on scroll
    const portfolioMarqueeImage = document.querySelector('.marquee--portfolio__images img');
    gsap.to(portfolioMarqueeImage, {
      scale: 1.1,
      scrollTrigger: {
        endTrigger: portfolioMarqueeImage,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      }
    });
    /* const portfolioMarqueeImageAnimation = gsap.from(portfolioMarqueeImage, {
      scale: 1.05,
      paused: true,
    }).progress(1);
    ScrollTrigger.create({
      trigger: portfolioMarqueeImage,
      start: 'top top',
      end: 'bottom bottom',
      onUpdate: (self) => {
        if (self.direction === -1) {
          portfolioMarqueeImageAnimation.reverse();
        } else {
          portfolioMarqueeImageAnimation.play();
        }
      },
    }); */

    // Canvas + Mesh Gradient
    const portfolioMarquee = document.querySelector('.marquee--portfolio');
    const meshGradientCanvas = document.querySelector('canvas.marquee--portfolio__canvas');
    let portfolioMarqueeHeight;
    if (window.matchMedia('(min-width: 1024px)').matches) {
      portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 210;
    } else if (window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches) {
      portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 140;
    } else if (window.matchMedia('(min-width: 200px) and (max-width: 768px)').matches) {
      portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 70;
    }

    // Scene
    const meshGradientScene = new THREE.Scene()

    // Colors
    let colorOne = new THREE.Color('#73005C');
    let colorTwo = new THREE.Color('#000050');
    let colorThree = new THREE.Color('#A60085');
    let colorFour = new THREE.Color('#000060');

    // Geometry
    const meshGradientGeometry = new THREE.PlaneBufferGeometry(2, 2);

    // Material
    const meshGradientMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTime: {
          value: Date.now()
        },
        uResolution: {
          value: new THREE.Vector3(1, 1)
        },
        uMouse: {
          value: new THREE.Vector2()
        },
        color1: {
          value: new THREE.Color(colorOne).convertLinearToSRGB()
        },
        color2: {
          value: new THREE.Color(colorTwo).convertLinearToSRGB()
        },
        color3: {
          value: new THREE.Color(colorThree).convertLinearToSRGB()
        },
        color4: {
          value: new THREE.Color(colorFour).convertLinearToSRGB()
        },
      },
      vertexShader: portfolioVertexShader,
      fragmentShader: portfolioFragmentShader
    });

    // Mesh
    const meshGradientMesh = new THREE.Mesh(meshGradientGeometry, meshGradientMaterial);
    meshGradientScene.add(meshGradientMesh);

    // Sizes
    const meshGradientSizes = {
      width: window.innerWidth,
      height: portfolioMarqueeHeight
    }

    // Resize
    window.addEventListener('resize', () => {
      if (window.matchMedia('(min-width: 1024px)').matches) {
        portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 210;
      } else if (window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches) {
        portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 140;
      } else if (window.matchMedia('(min-width: 200px) and (max-width: 768px)').matches) {
        portfolioMarqueeHeight = portfolioMarquee.offsetHeight - 70;
      }

      // Update sizes
      meshGradientSizes.width = window.innerWidth
      meshGradientSizes.height = portfolioMarqueeHeight

      // Update camera
      meshGradientCamera.aspect = meshGradientSizes.width / portfolioMarqueeHeight
      meshGradientCamera.updateProjectionMatrix()

      // Update renderer
      meshGradientRenderer.setSize(meshGradientSizes.width, portfolioMarqueeHeight)
      meshGradientRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      meshGradientMaterial.uniforms.uResolution.value.x = meshGradientRenderer.domElement.width;
      meshGradientMaterial.uniforms.uResolution.value.y = meshGradientRenderer.domElement.height;
    })

    // Camera
    const meshGradientCamera = new THREE.PerspectiveCamera(35, meshGradientSizes.width / meshGradientSizes.height, 0.1, 100)
    meshGradientCamera.position.z = 1;

    // Renderer
    const meshGradientRenderer = new THREE.WebGLRenderer({
      canvas: meshGradientCanvas,
    });
    meshGradientRenderer.setSize(meshGradientSizes.width, meshGradientSizes.height)
    meshGradientRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    meshGradientMaterial.uniforms.uResolution.value.x = meshGradientRenderer.domElement.width;
    meshGradientMaterial.uniforms.uResolution.value.y = meshGradientRenderer.domElement.height;

    // Animate
    const meshGradientClock = new THREE.Clock()

    const meshGradientTick = () => {
      const meshGradientElapsedTime = meshGradientClock.getElapsedTime()

      meshGradientMaterial.uniforms.uTime.value = meshGradientElapsedTime

      // Render
      meshGradientRenderer.render(meshGradientScene, meshGradientCamera)

      // Call tick again on the next frame
      window.requestAnimationFrame(meshGradientTick)
    }

    meshGradientTick();
  }
}
