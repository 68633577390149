/* --------------------------
* IMPORTS
* -------------------------- */
import { gsap } from 'gsap/all';
import Component from 'gia/Component';


/* --------------------------
* VARIABLES
* -------------------------- */
const mobileGallery = document.querySelector('.mobile')

/* --------------------------
* INIT
* -------------------------- */
export default class MobileGallery extends Component {
  /* constructor(MobileGallery) {
    super(MobileGallery);
  } */
  mount() {
    /**
     * Initialize all banners
     */
    const navigationPrev = document.querySelector('.mobile__pagination-arrow--prev');
    const navigationNext = document.querySelector('.mobile__pagination-arrow--next');
    const tabNavigation = Array.from(document.querySelectorAll('.mobile__images'));
    const dotNavigation = Array.from(document.querySelectorAll('.mobile__slideshow-dot'));
    const mobileSlidesNum = 3;
    let activeSlide = 0;
    let isAnimating = false;
    let windowCachedWidth;

    /**
     * Slide iteration function.
     */
    function gotoSlide(slideDirection) {
      if (!isAnimating) {
        isAnimating = true;

        if (slideDirection === 'next') {
          if (activeSlide < (mobileSlidesNum - 1)) {
            activeSlide++;
          } else {
            activeSlide = 0;
          }
        } else if (slideDirection === 'previous') {
          if (activeSlide > 0) {
            activeSlide--;
          } else {
            activeSlide = mobileSlidesNum - 1;
          }
        }

        tabNavigation.forEach((tabNavigationItem, index) => {
          if (activeSlide === index) {
            tabNavigation[index].classList.add('mobile__images--current');
          } else {
            tabNavigation[index].classList.remove('mobile__images--current');
          }
        });
        dotNavigation.forEach((dotNavigationItem, index) => {
          if (activeSlide === index) {
            dotNavigation[index].classList.add('mobile__slideshow-dot--current');
          } else {
            dotNavigation[index].classList.remove('mobile__slideshow-dot--current');
          }
        });

        gsap.delayedCall(0.4, () => {
          isAnimating = false;
        });
      }
    }

    /**
     * Navigation butto nevents.
     */
    navigationPrev.addEventListener('click', (e) => {
      e.preventDefault();
      gotoSlide('previous');
    });
    navigationPrev.addEventListener('mousedown', (e) => {
      e.preventDefault();
    });
    navigationNext.addEventListener('click', (e) => {
      e.preventDefault();
      gotoSlide('next');
    });
    navigationNext.addEventListener('mousedown', (e) => {
      e.preventDefault();
    });

    // Go to initial slide.
    gotoSlide();
  }
}