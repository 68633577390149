import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import millerChildrensMarqueeImageMain from './assets/img/miller-childrens-marquee--main.webp'
import millerChildrensMarqueeImageResponsive from './assets/img/miller-childrens-marquee--responsive.webp'

import millerChildrensGridVideoOneMain from './assets/img/miller-childrens-grid-one--main.mp4'
import millerChildrensGridVideoOneResponsive from './assets/img/miller-childrens-grid-one--responsive.mp4'

import millerChildrensGridVideoTwoMain from './assets/img/miller-childrens-grid-two--main.mp4'
import millerChildrensGridVideoTwoResponsive from './assets/img/miller-childrens-grid-two--responsive.mp4'

import millerChildrensGridImageThreeMain from './assets/img/miller-childrens-grid-three--main.webp'
import millerChildrensGridImageThreeResponsive from './assets/img/miller-childrens-grid-three--responsive.webp'

import millerChildrensGridImageFourMain from './assets/img/miller-childrens-grid-four--main.webp'
import millerChildrensGridImageFourResponsive from './assets/img/miller-childrens-grid-four--responsive.webp'

import millerChildrensGridVideoFiveMain from './assets/img/miller-childrens-grid-five--main.mp4'
import millerChildrensGridVideoFiveResponsive from './assets/img/miller-childrens-grid-five--responsive.mp4'

import millerChildrensGridVideoSixMain from './assets/img/miller-childrens-grid-six--main.mp4'
import millerChildrensGridVideoSixResponsive from './assets/img/miller-childrens-grid-six--responsive.mp4'

import millerChildrensMobileImageOneMain from './assets/img/miller-childrens-mobile-one--main.webp'
import millerChildrensMobileImageOneResponsive from './assets/img/miller-childrens-mobile-one--responsive.webp'

import millerChildrensMobileImageTwoMain from './assets/img/miller-childrens-mobile-two--main.webp'
import millerChildrensMobileImageTwoResponsive from './assets/img/miller-childrens-mobile-two--responsive.webp'

import millerChildrensMobileImageThreeMain from './assets/img/miller-childrens-mobile-three--main.webp'
import millerChildrensMobileImageThreeResponsive from './assets/img/miller-childrens-mobile-three--responsive.webp'


export default class MillerChildrensImages extends Component {

  mount() {
    // MARQUEE
    const millerChildrensMarqueeMain = document.querySelector('.miller-childrens .marquee--portfolio__images .marquee--portfolio__image--main')
    millerChildrensMarqueeMain.setAttribute("srcset", millerChildrensMarqueeImageMain)
    const millerChildrensMarqueeResponsive = document.querySelector('.miller-childrens .marquee--portfolio__images .marquee--portfolio__image--responsive')
    millerChildrensMarqueeResponsive.setAttribute("srcset", millerChildrensMarqueeImageResponsive)

    // GRID
    const millerChildrensGridOneMainVideo = document.querySelector('.miller-childrens .grid__image--one.main source')
    millerChildrensGridOneMainVideo.setAttribute("data-src", millerChildrensGridVideoOneMain)
    const millerChildrensGridOneResponsiveVideo = document.querySelector('.miller-childrens .grid__image--one.responsive source')
    millerChildrensGridOneResponsiveVideo.setAttribute("data-src", millerChildrensGridVideoOneResponsive)

    const millerChildrensGridTwoMainVideo = document.querySelector('.miller-childrens .grid__image--two.main source')
    millerChildrensGridTwoMainVideo.setAttribute("data-src", millerChildrensGridVideoTwoMain)
    const millerChildrensGridTwoResponsiveVideo = document.querySelector('.miller-childrens .grid__image--two.responsive source')
    millerChildrensGridTwoResponsiveVideo.setAttribute("data-src", millerChildrensGridVideoTwoResponsive)

    const millerChildrensGridThreeMain = document.querySelector('.miller-childrens .grid__image--three .grid__source--main')
    millerChildrensGridThreeMain.setAttribute("srcset", millerChildrensGridImageThreeMain)
    const millerChildrensGridThreeResponsive = document.querySelectorAll('.miller-childrens .grid__image--three .grid__source--responsive')
    millerChildrensGridThreeResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", millerChildrensGridImageThreeResponsive)
    });

    const millerChildrensGridFourMain = document.querySelector('.miller-childrens .grid__image--four .grid__source--main')
    millerChildrensGridFourMain.setAttribute("srcset", millerChildrensGridImageFourMain)
    const millerChildrensGridFourResponsive = document.querySelectorAll('.miller-childrens .grid__image--four .grid__source--responsive')
    millerChildrensGridFourResponsive.forEach((responsiveItem) => {
      responsiveItem.setAttribute("srcset", millerChildrensGridImageFourResponsive)
    });

    const millerChildrensGridFiveMainVideo = document.querySelector('.miller-childrens .grid__image--five.main source')
    millerChildrensGridFiveMainVideo.setAttribute("data-src", millerChildrensGridVideoFiveMain)
    const millerChildrensGridFiveResponsiveVideo = document.querySelector('.miller-childrens .grid__image--five.responsive source')
    millerChildrensGridFiveResponsiveVideo.setAttribute("data-src", millerChildrensGridVideoFiveResponsive)

    const millerChildrensGridSixMainVideo = document.querySelector('.miller-childrens .grid__image--six.main source')
    millerChildrensGridSixMainVideo.setAttribute("data-src", millerChildrensGridVideoSixMain)
    const millerChildrensGridSixResponsiveVideo = document.querySelector('.miller-childrens .grid__image--six.responsive source')
    millerChildrensGridSixResponsiveVideo.setAttribute("data-src", millerChildrensGridVideoSixResponsive)

    // MOBILE GALLERY
    const millerChildrensMobileOneMain = document.querySelector('.miller-childrens .mobile__images--one .mobile__image--main')
    millerChildrensMobileOneMain.setAttribute("srcset", millerChildrensMobileImageOneMain)
    const millerChildrensMobileOneResponsive = document.querySelector('.miller-childrens .mobile__images--one .mobile__image--responsive')
    millerChildrensMobileOneResponsive.setAttribute("srcset", millerChildrensMobileImageOneResponsive)

    const millerChildrensMobileTwoMain = document.querySelector('.miller-childrens .mobile__images--two .mobile__image--main')
    millerChildrensMobileTwoMain.setAttribute("srcset", millerChildrensMobileImageTwoMain)
    const millerChildrensMobileTwoResponsive = document.querySelector('.miller-childrens .mobile__images--two .mobile__image--responsive')
    millerChildrensMobileTwoResponsive.setAttribute("srcset", millerChildrensMobileImageTwoResponsive)

    const millerChildrensMobileThreeMain = document.querySelector('.miller-childrens .mobile__images--three .mobile__image--main')
    millerChildrensMobileThreeMain.setAttribute("srcset", millerChildrensMobileImageThreeMain)
    const millerChildrensMobileThreeResponsive = document.querySelector('.miller-childrens .mobile__images--three .mobile__image--responsive')
    millerChildrensMobileThreeResponsive.setAttribute("srcset", millerChildrensMobileImageThreeResponsive)
  }
}