import Component from 'gia/Component';
import * as THREE from 'three';
import { gsap } from 'gsap';
import toonTexture from '../../assets/img/toon.jpg';
// import reverseToonTexture from '../../assets/img/reverse-toon.jpg';
import sphereVertexShader from '../../assets/shaders/homeMarquee/vertex.glsl';
import sphereFragmentShader from '../../assets/shaders/homeMarquee/fragment.glsl';


/**
 * Animate Home Marquee
 */
export default class HomeMarqueeAnimation extends Component {
  mount() {
    function init() {
      const hideMarqueeAnimation = gsap.to('.marquee h1, .marquee h2, .marquee p, .marquee a', {
        clipPath:"inset(100% 0% 0% 0%)",
        paused: true,
        duration: 0.45,
        y: -20,
        rotateX: 45,
        autoAlpha: 0,
        ease: 'power2.easeInOut',
        stagger: 0.1,
      }).progress(0)
      document.querySelector('.site-header__menu-trigger').addEventListener('click', (e) => {
        e.preventDefault();
        if (document.body.classList.contains('dropdown-active')) {
          hideMarqueeAnimation.play();
        } else {
          hideMarqueeAnimation.reverse();
        }
      });

      const homeLink = document.querySelector('.site-header__logo')
      
      homeLink.addEventListener('click', (e) => {
        e.preventDefault();
        hideMarqueeAnimation.reverse();
      });

      // const homeMarqueeSpheres = gsap.timeline({ paused: true, yoyo: true, repeat: -1 });

      if (document.querySelector('.marquee--home')) {
        // homeMarqueeSpheres.play();
      } else {
        // homeMarqueeSpheres.kill();
      }
      
    }
    init();

    // Spheres THREE.JS

    /**
    * Base
    */
    const parameters = {
      materialColor: '#ffeded'
    }
    // Canvas
    const canvas = document.querySelector('canvas.marquee--home__canvas');
    // Scene
    const scene = new THREE.Scene()


    /**
    * Objects
    */
    // Texture
    const spheresGeometry = new THREE.BufferGeometry()
    const spheresCount = 350
    // need to provide three coordinates for each particle
    const positionArray = new Float32Array(spheresCount * 3)
    const scaleArray = new Float32Array(spheresCount)

    for (let i = 0; i < spheresCount; i++) {
      positionArray[i * 3 + 0] = (Math.random() - 0.5) * 6
      positionArray[i * 3 + 1] = (Math.random() - 0.5) * 6
      positionArray[i * 3 + 2] = (Math.random() - 0.5) * 6

      scaleArray[i] = Math.random()
    }

    // '3' is specifying how many values per vertex
    spheresGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
    spheresGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))


    // Material
    const spheresMaterial = new THREE.ShaderMaterial({
      transparent: true,
      depthWrite: false,
      uniforms: {
        uTime: {
          value: 0
        },
        uPixelRatio: {
          value: Math.min(window.devicePixelRatio, 2)
        },
        uSize: {
          value: 200
        },
        uResolution: { 
          value: new THREE.Vector3() 
        }
      },
      vertexShader: sphereVertexShader,
      fragmentShader: sphereFragmentShader
    })

    const spheresPoints = new THREE.Points(spheresGeometry, spheresMaterial)
    scene.add(spheresPoints);


    /**
    * Sizes
    */
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      spheresMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
    })


    /**
    * Camera
    */
    // Group
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    // Base camera
    const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
    camera.position.z = 6
    cameraGroup.add(camera)


    /**
    * Renderer
    */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true
    })
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    spheresMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)


    /**
    * Cursor
    */
    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    window.addEventListener('mousemove', (event) => {
      cursor.x = event.clientX / sizes.width - 0.5
      cursor.y = event.clientY / sizes.height - 0.5
    })

    /**
    * Animate
    */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      const deltaTime = elapsedTime - previousTime
      previousTime = elapsedTime

      spheresMaterial.uniforms.uTime.value = elapsedTime

      // Animate camera
      camera.position.y = sizes.width / sizes.height - 0.5

      const parallaxX = cursor.x * 0.25
      const parallaxY = - cursor.y * 0.25
      cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
      cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
    }

    tick();
  }
}