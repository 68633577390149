/* --------------------------
* IMPORTS
* -------------------------- */
import Component from 'gia/Component';


/* --------------------------
* INIT
* -------------------------- */
export default class GridGallery extends Component {
  mount() {
    const gridGallery = document.querySelector('.grid')
    let currentVideo;

    if (gridGallery) {
      const gridVideos = document.querySelectorAll('.grid__images--video')
      gridVideos.forEach((gridVideo) => {
        gridVideo.addEventListener('click', (e) => {
          e.preventDefault();
          if (window.matchMedia('(min-width: 1800px)').matches) {
            currentVideo = gridVideo.querySelector('.responsive')
          } else if (window.matchMedia('(max-width: 1800px) and (min-width: 768px)')) {
            currentVideo = gridVideo.querySelector('.main')
          } else {
            currentVideo = gridVideo.querySelector('.responsive')
          }
          if (gridVideo.classList.contains('paused')) {
            currentVideo.play()
            gridVideo.classList.remove('paused')
            gridVideo.classList.add('play')
          } else {
            currentVideo.pause()
            gridVideo.classList.remove('play')
            gridVideo.classList.add('paused')
          }
        })
      })
    }
  }
}