/* --------------------------
* IMPORTS
* -------------------------- */
import Component from 'gia/Component';


/* --------------------------
* INIT
* -------------------------- */
export default class DigAds extends Component {
  mount() {
    const digAdsGallery = document.querySelector('.dig-ads')
    let currentDigAdVideo;

    if (digAdsGallery) {
      const digAdsVideos = document.querySelectorAll('.dig-ads__dig-ads--video')
      digAdsVideos.forEach((digAdsVideo) => {
        digAdsVideo.addEventListener('click', (e) => {
          e.preventDefault();
          currentDigAdVideo = digAdsVideo.querySelector('.dig-ads__dig-ad');
          
          if (digAdsVideo.classList.contains('paused')) {
            currentDigAdVideo.play()
            digAdsVideo.classList.remove('paused')
            digAdsVideo.classList.add('play')
          } else {
            currentDigAdVideo.pause()
            digAdsVideo.classList.remove('play')
            digAdsVideo.classList.add('paused')
          }
        })
        digAdsVideo.querySelector('.dig-ads__dig-ad').addEventListener('ended', hasEnded, false);
        function hasEnded() {
          digAdsVideo.classList.remove('play')
          digAdsVideo.classList.add('paused')
        }
      })
    }
  }
}