import Component from 'gia/Component';

// IMAGES -- DELETE WHEN GOING LIVE AND UPDATE PATHS
import moreMarqueeImageMain from './assets/img/more-marquee--main.webp'
import moreMarqueeImageResponsive from './assets/img/more-marquee--responsive.webp'

import moreFullWidthImagesBowlapaloozaVideoOneMain from './assets/img/bowlapalooza-marquee--main.mp4'
import moreFullWidthImagesBowlapaloozaVideoOneResponsive from './assets/img/bowlapalooza-marquee--responsive.mp4'
import moreFullWidthImagesBowlapaloozaVideoTwoMain from './assets/img/bowlapalooza-team--main.mp4'
import moreFullWidthImagesBowlapaloozaVideoTwoResponsive from './assets/img/bowlapalooza-team--responsive.mp4'
import moreFullWidthImagesBowlapaloozaVideoThreeMain from './assets/img/bowlapalooza-details--main.mp4'
import moreFullWidthImagesBowlapaloozaVideoThreeResponsive from './assets/img/bowlapalooza-details--responsive.mp4'

import moreFullWidthImagesCushmanImageOneMain from './assets/img/more-cushman-1--main.webp'
import moreFullWidthImagesCushmanImageOneResponsive from './assets/img/more-cushman-1--responsive.webp'
import moreFullWidthImagesCushmanImageTwoMain from './assets/img/more-cushman-2--main.webp'
import moreFullWidthImagesCushmanImageTwoResponsive from './assets/img/more-cushman-2--responsive.webp'
import moreFullWidthImagesCushmanImageThreeMain from './assets/img/more-cushman-3--main.webp'
import moreFullWidthImagesCushmanImageThreeResponsive from './assets/img/more-cushman-3--responsive.webp'
import moreFullWidthImagesCushmanImageFourMain from './assets/img/more-cushman-4--main.webp'
import moreFullWidthImagesCushmanImageFourResponsive from './assets/img/more-cushman-4--responsive.webp'

import moreDigAdsHumminbirdOne from './assets/img/humminbird-dig-ad-1.mp4'
import moreDigAdsHumminbirdTwo from './assets/img/humminbird-dig-ad-2.mp4'
import moreDigAdsJLGOne from './assets/img/jlg-dig-ad-1.mp4'
import moreDigAdsJLGThree from './assets/img/jlg-dig-ad-3.mp4'
import moreDigAdsMinnkotaOne from './assets/img/minnkota-dig-ad-1.mp4'
import moreDigAdsBadlandsOne from './assets/img/badlands-dig-ad-1.mp4'
import moreDigAdsMadonnaOne from './assets/img/madonna-dig-ad-1.mp4'
import moreDigAdsMemorialCareOne from './assets/img/memorialcare-dig-ad-1.mp4'
import moreDigAdsWallsOne from './assets/img/walls-dig-ad-1.mp4'


export default class moreImages extends Component {

  mount() {
    // MARQUEE
    const moreMarqueeMain = document.querySelector('.more .marquee--portfolio__images .marquee--portfolio__image--main')
    moreMarqueeMain.setAttribute("srcset", moreMarqueeImageMain)
    const moreMarqueeResponsive = document.querySelector('.more .marquee--portfolio__images .marquee--portfolio__image--responsive')
    moreMarqueeResponsive.setAttribute("srcset", moreMarqueeImageResponsive)

    // BOWLAPALOOZA
    const moreFullWidthImagesBowlapaloozaVideoOneMainVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-one.main source')
    moreFullWidthImagesBowlapaloozaVideoOneMainVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoOneMain)
    const moreFullWidthImagesBowlapaloozaVideoOneResponsiveVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-one.responsive source')
    moreFullWidthImagesBowlapaloozaVideoOneResponsiveVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoOneResponsive)
    const moreFullWidthImagesBowlapaloozaVideoTwoMainVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-two.main source')
    moreFullWidthImagesBowlapaloozaVideoTwoMainVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoTwoMain)
    const moreFullWidthImagesBowlapaloozaVideoTwoResponsiveVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-two.responsive source')
    moreFullWidthImagesBowlapaloozaVideoTwoResponsiveVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoTwoResponsive)
    const moreFullWidthImagesBowlapaloozaVideoThreeMainVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-three.main source')
    moreFullWidthImagesBowlapaloozaVideoThreeMainVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoThreeMain)
    const moreFullWidthImagesBowlapaloozaVideoThreeResponsiveVideo = document.querySelector('.more .full-width-images__image--bowlapalooza-three.responsive source')
    moreFullWidthImagesBowlapaloozaVideoThreeResponsiveVideo.setAttribute("data-src", moreFullWidthImagesBowlapaloozaVideoThreeResponsive)

    // CUSHMAN
    const moreFullWidthImagesCushmanOneMain = document.querySelector('.more .full-width-images__images--cushman-one .full-width-images__source--main')
    moreFullWidthImagesCushmanOneMain.setAttribute("srcset", moreFullWidthImagesCushmanImageOneMain)
    const moreFullWidthImagesCushmanOneResponsive = document.querySelector('.more .full-width-images__images--cushman-one .full-width-images__source--responsive')
    moreFullWidthImagesCushmanOneResponsive.setAttribute("srcset", moreFullWidthImagesCushmanImageOneResponsive)
    const moreFullWidthImagesCushmanTwoMain = document.querySelector('.more .full-width-images__images--cushman-two .full-width-images__source--main')
    moreFullWidthImagesCushmanTwoMain.setAttribute("srcset", moreFullWidthImagesCushmanImageTwoMain)
    const moreFullWidthImagesCushmanTwoResponsive = document.querySelector('.more .full-width-images__images--cushman-two .full-width-images__source--responsive')
    moreFullWidthImagesCushmanTwoResponsive.setAttribute("srcset", moreFullWidthImagesCushmanImageTwoResponsive)
    const moreFullWidthImagesCushmanThreeMain = document.querySelector('.more .full-width-images__images--cushman-three .full-width-images__source--main')
    moreFullWidthImagesCushmanThreeMain.setAttribute("srcset", moreFullWidthImagesCushmanImageThreeMain)
    const moreFullWidthImagesCushmanThreeResponsive = document.querySelector('.more .full-width-images__images--cushman-three .full-width-images__source--responsive')
    moreFullWidthImagesCushmanThreeResponsive.setAttribute("srcset", moreFullWidthImagesCushmanImageThreeResponsive)
    const moreFullWidthImagesCushmanFourMain = document.querySelector('.more .full-width-images__images--cushman-four .full-width-images__source--main')
    moreFullWidthImagesCushmanFourMain.setAttribute("srcset", moreFullWidthImagesCushmanImageFourMain)
    const moreFullWidthImagesCushmanFourResponsive = document.querySelector('.more .full-width-images__images--cushman-four .full-width-images__source--responsive')
    moreFullWidthImagesCushmanFourResponsive.setAttribute("srcset", moreFullWidthImagesCushmanImageFourResponsive)

    // DIG ADS
    const moreDigAdsJLGOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--jlg-one source')
    moreDigAdsJLGOneVideo.setAttribute("data-src", moreDigAdsJLGOne)
    const moreDigAdsHumminbirdOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--humminbird-one source')
    moreDigAdsHumminbirdOneVideo.setAttribute("data-src", moreDigAdsHumminbirdOne)
    const moreDigAdsMinnkotaOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--minnkota-one source')
    moreDigAdsMinnkotaOneVideo.setAttribute("data-src", moreDigAdsMinnkotaOne)
    
    const moreDigAdsBadlandsOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--badlands-one source')
    moreDigAdsBadlandsOneVideo.setAttribute("data-src", moreDigAdsBadlandsOne)
    const moreDigAdsMemorialCareOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--memorialcare-one source')
    moreDigAdsMemorialCareOneVideo.setAttribute("data-src", moreDigAdsMemorialCareOne)
    const moreDigAdsJLGThreeVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--jlg-three source')
    moreDigAdsJLGThreeVideo.setAttribute("data-src", moreDigAdsJLGThree)

    const moreDigAdsHumminbirdTwoVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--humminbird-two source')
    moreDigAdsHumminbirdTwoVideo.setAttribute("data-src", moreDigAdsHumminbirdTwo)
    const moreDigAdsWallsOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--walls-one source')
    moreDigAdsWallsOneVideo.setAttribute("data-src", moreDigAdsWallsOne)
    const moreDigAdsMadonnaOneVideo = document.querySelector('.dig-ads .dig-ads__dig-ad--madonna-one source')
    moreDigAdsMadonnaOneVideo.setAttribute("data-src", moreDigAdsMadonnaOne)
  }
}